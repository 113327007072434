<!--
 * @Author: jiang
 * @Date: 2021-07-18 11:28:37
 * @Description: 区块
-->
<template>
  <div class="b-section">
    <div class="b-section-header">
      <slot name="title">
        <div
          v-if="title"
          class="title"
        >
          {{ title}}
        </div>
      </slot>
      <div class="content">
        <slot name="header"></slot>
      </div>
      <el-link
        v-if="more"
        class="more"
        type="info"
        @click="$emit('more')"
      >查看更多</el-link>
    </div>
    <div class="b-section-main">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: String,
    more: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.b-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .b-section-main {
    flex: 1;
    min-width: 0;
    overflow: hidden;
  }
}

.b-section-header {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  border-bottom: 1px solid #f1f1f1;

  .title {
    position: relative;
    padding-left: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #2b2d42;

    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      width: 3px;
      height: 16px;
      margin-top: -8px;
      content: '';
      background: #04a37e;
      border-radius: 2px;
    }
  }

  .content {
    flex: 1;
    min-width: 0;
  }

  .more {
    font-size: 12px;
    font-weight: 400;
    color: #b6babe;
  }
}
</style>
